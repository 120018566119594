import { User, Membership, ContactInfo } from "../codegen/graphql"

export const createAndSetCookie = (cookieName: string, cookieValue: string, hourToExpire: number) => {
  let date = new Date()
  date.setTime(date.getTime() + hourToExpire * 60 * 60 * 1000)
  const cookieString = cookieName + " = " + cookieValue + ";max-age=" + date.getTime() / 1000 + ";samesite=strict;"
  document.cookie = cookieString
}

export type UserSortField = keyof Pick<
  User & Membership & ContactInfo,
  "email" | "municipality" | "status" | "firstname"
>
export type SortDirection = "asc" | "desc"
