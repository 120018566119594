import { ChangeEvent, Fragment, useMemo, useState } from "react"
import { Button, Grid, TextField, Typography } from "@mui/material"
import { Membership, refetchGetUserQuery, UpdateMembershipInput, useUpdateMembershipMutation } from "../codegen/graphql"
import { Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon } from "@mui/icons-material"
import { xor } from "lodash"
import { useSnackbar } from "../hooks/useSnackbarHook"

interface Props {
  userId: string
  membership: Membership
}
type MembershipFormLabel = "expireAt" | "status" | "type" | "notes"
const EXPIRE_AT = "Giltligt till"
const NOTES = "Anteckning"
const STATUS = "Status"
const TYPE = "Medlemstyp"

export default function MembershipForm({ membership, userId }: Props) {
  const { setSnackbarState } = useSnackbar()
  const [isEdit, setIsEdit] = useState(false)
  const initialFormState = useMemo(() => {
    const initialFormState: Record<MembershipFormLabel, string> = {
      expireAt: membership.expireAt || "",
      notes: membership.notes || "",
      status: membership.status || "",
      type: membership.type || "",
    }
    return initialFormState
  }, [membership])

  const [updateMembershipMutation] = useUpdateMembershipMutation({
    onError: (e) => {
      setSnackbarState({ message: "Error updating membership: " + e.message, type: "error" })
    },
    onCompleted: (res) => {
      setSnackbarState({ message: "Membership updated", type: "info" })
    },
  })

  const [formState, setFormState] = useState(initialFormState)

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState((oldState) => {
      return { ...oldState, [event.target.name]: event.target.value }
    })
  }

  const handleCancel = () => {
    setIsEdit(false)
  }

  const handleSave = () => {
    setIsEdit(false)
    const membershipPayload: UpdateMembershipInput = {
      userId: userId,
      expireAt: formState.expireAt,
      notes: formState.notes,
      status: formState.status,
      type: formState.type,
    }

    const formStateDelta = xor(Object.values(formState), Object.values(initialFormState))
    if (formStateDelta.length < 1) return

    updateMembershipMutation({
      variables: { input: membershipPayload },
      refetchQueries: [refetchGetUserQuery({ id: userId })],
    })
  }

  return (
    <Fragment>
      <Typography variant="h4">Adminpanel</Typography>
      <Typography variant="caption" gutterBottom>
        Fält endast synliga för admin
      </Typography>
      <Typography variant="h6" paddingTop="1rem">
        Medlemskap
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!isEdit}
            required
            id="expireAt"
            name="expireAt"
            label={EXPIRE_AT}
            fullWidth
            autoComplete="expire-at"
            variant="standard"
            value={formState.expireAt.split("T")[0]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!isEdit}
            required
            id="notes"
            name="notes"
            label={NOTES}
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={formState.notes}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!isEdit}
            required
            id="status"
            name="status"
            label={STATUS}
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={formState.status}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!isEdit}
            required
            id="type"
            name="type"
            label={TYPE}
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={formState.type}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      {isEdit ? (
        <>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault()
              handleSave()
            }}>
            <SaveIcon />
            <Typography>Spara</Typography>
          </Button>
          <Button
            onClick={(e) => {
              handleCancel()
            }}>
            <CancelIcon />
            <Typography sx={{ paddingLeft: 1 }}>Avbryt</Typography>
          </Button>
        </>
      ) : (
        <Button
          onClick={(e) => {
            setIsEdit(true)
          }}>
          <EditIcon />
          <Typography sx={{ paddingLeft: 1 }}>Editera medlemskap</Typography>
        </Button>
      )}
    </Fragment>
  )
}
