import { createTheme, ThemeOptions } from "@mui/material/styles"

const themeOptions: ThemeOptions = {
  // palette: {
  //   // mode: "dark",
  // },
  // components: {
  //   MuiContainer: {
  //     styleOverrides: {
  //       root: {},
  //     },
  //   },
  // MuiButtonBase: {
  //   styleOverrides: {
  //     // root: {
  //       // borderRadius: "20px",
  //     // },
  //   },
  // },
  // },
}

export const customThemeOverride = createTheme(themeOptions)
