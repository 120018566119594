import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type ContactInfo = {
  __typename?: 'ContactInfo';
  GDPR?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  firstname?: Maybe<Scalars['String']>;
  lastEdited?: Maybe<Scalars['DateTime']>;
  lastname?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  streetAddress?: Maybe<Scalars['String']>;
};

export type CreateContactInfoInput = {
  GDPR?: Maybe<Scalars['Boolean']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type CreateMembershipInput = {
  expireAt: Scalars['DateTime'];
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateUserInput = {
  contactInfo?: Maybe<CreateContactInfoInput>;
  email: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  membership?: Maybe<CreateMembershipInput>;
  password?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionsInput>;
  role?: Maybe<RoleEnum>;
};


export type LoginResponse = {
  __typename?: 'LoginResponse';
  sessionToken?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Membership = {
  __typename?: 'Membership';
  createdAt: Scalars['DateTime'];
  expireAt: Scalars['DateTime'];
  lastEdited?: Maybe<Scalars['DateTime']>;
  notes?: Maybe<Scalars['String']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createContactInfo: Scalars['ID'];
  createMembership: Scalars['ID'];
  createUser: Scalars['ID'];
  hardDeleteUser: Scalars['Boolean'];
  login: LoginResponse;
  passwordLogin: LoginResponse;
  softDeleteUser: Scalars['Boolean'];
  updateContactInfo: ContactInfo;
  updateMembership: Membership;
  updatePermissions: User;
  updateRole: User;
  updateUser: User;
  updateUserPassword: Scalars['Boolean'];
};


export type MutationCreateContactInfoArgs = {
  input: CreateContactInfoInput;
};


export type MutationCreateMembershipArgs = {
  input: CreateMembershipInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationHardDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationLoginArgs = {
  clientId: Scalars['String'];
  credentials: Scalars['String'];
};


export type MutationPasswordLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSoftDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationUpdateContactInfoArgs = {
  input: UpdateContactInfoInput;
};


export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput;
};


export type MutationUpdatePermissionsArgs = {
  input: PermissionsInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserPasswordArgs = {
  input: UpdatePasswordInput;
};

export type PermissionsInput = {
  municipalities: Array<Scalars['String']>;
};

export type PermissionsType = {
  __typename?: 'PermissionsType';
  municipalities: Array<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getAllMunicipalities: Array<Scalars['String']>;
  getAllUsers: Array<User>;
  getMe: User;
  getUser: User;
  searchUsers: UserSearchResult;
  sessionLogin: LoginResponse;
};


export type QueryGetAllUsersArgs = {
  limit?: Maybe<Scalars['Float']>;
  nPerPage?: Maybe<Scalars['Float']>;
  pageNumber?: Maybe<Scalars['Float']>;
};


export type QueryGetUserArgs = {
  id: Scalars['String'];
};


export type QuerySearchUsersArgs = {
  input: SearchUserInput;
};


export type QuerySessionLoginArgs = {
  token: Scalars['String'];
};

/** Possible user roles that provide different premissions */
export enum RoleEnum {
  /** Can see and edit users of their assigned municipalities */
  Admin = 'ADMIN',
  /** Can see and edit everything, including all users and their permissions. */
  Superadmin = 'SUPERADMIN',
  /** Can only see and edit their own profile and contact data. Not their membership data. */
  User = 'USER'
}

export type SearchUserInput = {
  limit?: Maybe<Scalars['Float']>;
  municipalityFilter?: Maybe<Array<Scalars['String']>>;
  pageNumber?: Maybe<Scalars['Float']>;
  searchString?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};

export type UpdateContactInfoInput = {
  GDPR?: Maybe<Scalars['Boolean']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type UpdateMembershipInput = {
  expireAt?: Maybe<Scalars['DateTime']>;
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type UpdatePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  userId: Scalars['String'];
};

export type UpdateRoleInput = {
  _id: Scalars['String'];
  role: RoleEnum;
};

export type UpdateUserInput = {
  _id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  removedAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<RoleEnum>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  contactInfo?: Maybe<ContactInfo>;
  email: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  lastEdited?: Maybe<Scalars['DateTime']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  membership?: Maybe<Membership>;
  permissions?: Maybe<PermissionsType>;
  removedAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<RoleEnum>;
};

export type UserSearchResult = {
  __typename?: 'UserSearchResult';
  pageNo?: Maybe<Scalars['Float']>;
  totalPages?: Maybe<Scalars['Float']>;
  users?: Maybe<Array<User>>;
};

export type MembershipFragment = { __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string };

export type ContactInfoFragment = { __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> };

export type UserFragment = { __typename?: 'User', _id: string, email: string, lastEdited?: Maybe<any>, lastLogin?: Maybe<any>, role?: Maybe<RoleEnum>, removedAt?: Maybe<any>, imageUrl?: Maybe<string>, contactInfo?: Maybe<{ __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> }>, membership?: Maybe<{ __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string }>, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> };

export type LoginResponseFragment = { __typename?: 'LoginResponse', sessionToken?: Maybe<string>, user?: Maybe<{ __typename?: 'User', _id: string, email: string, lastEdited?: Maybe<any>, lastLogin?: Maybe<any>, role?: Maybe<RoleEnum>, removedAt?: Maybe<any>, imageUrl?: Maybe<string>, contactInfo?: Maybe<{ __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> }>, membership?: Maybe<{ __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string }>, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> }> };

export type UserSearchResultFragment = { __typename?: 'UserSearchResult', totalPages?: Maybe<number>, pageNo?: Maybe<number>, users?: Maybe<Array<{ __typename?: 'User', _id: string, email: string, lastEdited?: Maybe<any>, lastLogin?: Maybe<any>, role?: Maybe<RoleEnum>, removedAt?: Maybe<any>, imageUrl?: Maybe<string>, contactInfo?: Maybe<{ __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> }>, membership?: Maybe<{ __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string }>, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> }>> };

export type LoginMutationVariables = Exact<{
  loginClientId: Scalars['String'];
  loginCredentials: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', sessionToken?: Maybe<string>, user?: Maybe<{ __typename?: 'User', _id: string, email: string, lastEdited?: Maybe<any>, lastLogin?: Maybe<any>, role?: Maybe<RoleEnum>, removedAt?: Maybe<any>, imageUrl?: Maybe<string>, contactInfo?: Maybe<{ __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> }>, membership?: Maybe<{ __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string }>, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> }> } };

export type PasswordLoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type PasswordLoginMutation = { __typename?: 'Mutation', passwordLogin: { __typename?: 'LoginResponse', sessionToken?: Maybe<string>, user?: Maybe<{ __typename?: 'User', _id: string, email: string, lastEdited?: Maybe<any>, lastLogin?: Maybe<any>, role?: Maybe<RoleEnum>, removedAt?: Maybe<any>, imageUrl?: Maybe<string>, contactInfo?: Maybe<{ __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> }>, membership?: Maybe<{ __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string }>, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> }> } };

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', _id: string, email: string, lastEdited?: Maybe<any>, lastLogin?: Maybe<any>, role?: Maybe<RoleEnum>, removedAt?: Maybe<any>, imageUrl?: Maybe<string>, contactInfo?: Maybe<{ __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> }>, membership?: Maybe<{ __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string }>, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> } };

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'User', _id: string, role?: Maybe<RoleEnum> } };

export type SessionLoginQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type SessionLoginQuery = { __typename?: 'Query', sessionLogin: { __typename?: 'LoginResponse', sessionToken?: Maybe<string>, user?: Maybe<{ __typename?: 'User', _id: string, email: string, lastEdited?: Maybe<any>, lastLogin?: Maybe<any>, role?: Maybe<RoleEnum>, removedAt?: Maybe<any>, imageUrl?: Maybe<string>, contactInfo?: Maybe<{ __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> }>, membership?: Maybe<{ __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string }>, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> }> } };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'Query', getAllUsers: Array<{ __typename?: 'User', _id: string, email: string, lastEdited?: Maybe<any>, lastLogin?: Maybe<any>, role?: Maybe<RoleEnum>, removedAt?: Maybe<any>, imageUrl?: Maybe<string>, contactInfo?: Maybe<{ __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> }>, membership?: Maybe<{ __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string }>, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> }> };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', getMe: { __typename?: 'User', _id: string, email: string, lastEdited?: Maybe<any>, lastLogin?: Maybe<any>, role?: Maybe<RoleEnum>, removedAt?: Maybe<any>, imageUrl?: Maybe<string>, contactInfo?: Maybe<{ __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> }>, membership?: Maybe<{ __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string }>, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> } };

export type UpdateMembershipMutationVariables = Exact<{
  input: UpdateMembershipInput;
}>;


export type UpdateMembershipMutation = { __typename?: 'Mutation', updateMembership: { __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string } };

export type CreateMembershipMutationVariables = Exact<{
  input: CreateMembershipInput;
}>;


export type CreateMembershipMutation = { __typename?: 'Mutation', createMembership: string };

export type UpdateContactInfoMutationVariables = Exact<{
  input: UpdateContactInfoInput;
}>;


export type UpdateContactInfoMutation = { __typename?: 'Mutation', updateContactInfo: { __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> } };

export type CreateContactInfoMutationVariables = Exact<{
  input: CreateContactInfoInput;
}>;


export type CreateContactInfoMutation = { __typename?: 'Mutation', createContactInfo: string };

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', _id: string, email: string, lastEdited?: Maybe<any>, lastLogin?: Maybe<any>, role?: Maybe<RoleEnum>, removedAt?: Maybe<any>, imageUrl?: Maybe<string>, contactInfo?: Maybe<{ __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> }>, membership?: Maybe<{ __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string }>, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> } };

export type SearchUsersQueryVariables = Exact<{
  input: SearchUserInput;
}>;


export type SearchUsersQuery = { __typename?: 'Query', searchUsers: { __typename?: 'UserSearchResult', totalPages?: Maybe<number>, pageNo?: Maybe<number>, users?: Maybe<Array<{ __typename?: 'User', _id: string, email: string, lastEdited?: Maybe<any>, lastLogin?: Maybe<any>, role?: Maybe<RoleEnum>, removedAt?: Maybe<any>, imageUrl?: Maybe<string>, contactInfo?: Maybe<{ __typename?: 'ContactInfo', createdAt: any, firstname?: Maybe<string>, GDPR?: Maybe<boolean>, lastEdited?: Maybe<any>, lastname?: Maybe<string>, municipality?: Maybe<string>, postalCode?: Maybe<string>, streetAddress?: Maybe<string>, phone?: Maybe<string>, removedAt?: Maybe<any> }>, membership?: Maybe<{ __typename?: 'Membership', createdAt: any, expireAt: any, lastEdited?: Maybe<any>, notes?: Maybe<string>, removedAt?: Maybe<any>, status?: Maybe<string>, type: string }>, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> }>> } };

export type GetAllMunicipalitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMunicipalitiesQuery = { __typename?: 'Query', getAllMunicipalities: Array<string> };

export type UpdatePermissionsMutationVariables = Exact<{
  input: PermissionsInput;
}>;


export type UpdatePermissionsMutation = { __typename?: 'Mutation', updatePermissions: { __typename?: 'User', _id: string, permissions?: Maybe<{ __typename?: 'PermissionsType', municipalities: Array<string> }> } };

export const ContactInfoFragmentDoc = gql`
    fragment ContactInfo on ContactInfo {
  createdAt
  firstname
  GDPR
  lastEdited
  lastname
  municipality
  postalCode
  streetAddress
  phone
  removedAt
}
    `;
export const MembershipFragmentDoc = gql`
    fragment Membership on Membership {
  createdAt
  expireAt
  lastEdited
  notes
  removedAt
  status
  type
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  _id
  contactInfo {
    ...ContactInfo
  }
  email
  lastEdited
  lastLogin
  membership {
    ...Membership
  }
  role
  removedAt
  imageUrl
  permissions {
    municipalities
  }
}
    ${ContactInfoFragmentDoc}
${MembershipFragmentDoc}`;
export const LoginResponseFragmentDoc = gql`
    fragment LoginResponse on LoginResponse {
  user {
    ...User
  }
  sessionToken
}
    ${UserFragmentDoc}`;
export const UserSearchResultFragmentDoc = gql`
    fragment UserSearchResult on UserSearchResult {
  totalPages
  pageNo
  users {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const LoginDocument = gql`
    mutation Login($loginClientId: String!, $loginCredentials: String!) {
  login(clientId: $loginClientId, credentials: $loginCredentials) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginClientId: // value for 'loginClientId'
 *      loginCredentials: // value for 'loginCredentials'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const PasswordLoginDocument = gql`
    mutation PasswordLogin($email: String!, $password: String!) {
  passwordLogin(email: $email, password: $password) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;
export type PasswordLoginMutationFn = Apollo.MutationFunction<PasswordLoginMutation, PasswordLoginMutationVariables>;

/**
 * __usePasswordLoginMutation__
 *
 * To run a mutation, you first call `usePasswordLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordLoginMutation, { data, loading, error }] = usePasswordLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function usePasswordLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PasswordLoginMutation, PasswordLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PasswordLoginMutation, PasswordLoginMutationVariables>(PasswordLoginDocument, options);
      }
export type PasswordLoginMutationHookResult = ReturnType<typeof usePasswordLoginMutation>;
export type PasswordLoginMutationResult = Apollo.MutationResult<PasswordLoginMutation>;
export type PasswordLoginMutationOptions = Apollo.BaseMutationOptions<PasswordLoginMutation, PasswordLoginMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($updateUserInput: UpdateUserInput!) {
  updateUser(input: $updateUserInput) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    _id
    role
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const SessionLoginDocument = gql`
    query SessionLogin($token: String!) {
  sessionLogin(token: $token) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;

/**
 * __useSessionLoginQuery__
 *
 * To run a query within a React component, call `useSessionLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionLoginQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSessionLoginQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SessionLoginQuery, SessionLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SessionLoginQuery, SessionLoginQueryVariables>(SessionLoginDocument, options);
      }
export function useSessionLoginLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SessionLoginQuery, SessionLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SessionLoginQuery, SessionLoginQueryVariables>(SessionLoginDocument, options);
        }
export type SessionLoginQueryHookResult = ReturnType<typeof useSessionLoginQuery>;
export type SessionLoginLazyQueryHookResult = ReturnType<typeof useSessionLoginLazyQuery>;
export type SessionLoginQueryResult = Apollo.QueryResult<SessionLoginQuery, SessionLoginQueryVariables>;
export function refetchSessionLoginQuery(variables?: SessionLoginQueryVariables) {
      return { query: SessionLoginDocument, variables: variables }
    }
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  getAllUsers {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export function refetchGetAllUsersQuery(variables?: GetAllUsersQueryVariables) {
      return { query: GetAllUsersDocument, variables: variables }
    }
export const GetMeDocument = gql`
    query GetMe {
  getMe {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export function refetchGetMeQuery(variables?: GetMeQueryVariables) {
      return { query: GetMeDocument, variables: variables }
    }
export const UpdateMembershipDocument = gql`
    mutation UpdateMembership($input: UpdateMembershipInput!) {
  updateMembership(input: $input) {
    ...Membership
  }
}
    ${MembershipFragmentDoc}`;
export type UpdateMembershipMutationFn = Apollo.MutationFunction<UpdateMembershipMutation, UpdateMembershipMutationVariables>;

/**
 * __useUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipMutation, { data, loading, error }] = useUpdateMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(UpdateMembershipDocument, options);
      }
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>;
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>;
export type UpdateMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>;
export const CreateMembershipDocument = gql`
    mutation CreateMembership($input: CreateMembershipInput!) {
  createMembership(input: $input)
}
    `;
export type CreateMembershipMutationFn = Apollo.MutationFunction<CreateMembershipMutation, CreateMembershipMutationVariables>;

/**
 * __useCreateMembershipMutation__
 *
 * To run a mutation, you first call `useCreateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMembershipMutation, { data, loading, error }] = useCreateMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMembershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMembershipMutation, CreateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMembershipMutation, CreateMembershipMutationVariables>(CreateMembershipDocument, options);
      }
export type CreateMembershipMutationHookResult = ReturnType<typeof useCreateMembershipMutation>;
export type CreateMembershipMutationResult = Apollo.MutationResult<CreateMembershipMutation>;
export type CreateMembershipMutationOptions = Apollo.BaseMutationOptions<CreateMembershipMutation, CreateMembershipMutationVariables>;
export const UpdateContactInfoDocument = gql`
    mutation UpdateContactInfo($input: UpdateContactInfoInput!) {
  updateContactInfo(input: $input) {
    ...ContactInfo
  }
}
    ${ContactInfoFragmentDoc}`;
export type UpdateContactInfoMutationFn = Apollo.MutationFunction<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>;

/**
 * __useUpdateContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdateContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactInfoMutation, { data, loading, error }] = useUpdateContactInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>(UpdateContactInfoDocument, options);
      }
export type UpdateContactInfoMutationHookResult = ReturnType<typeof useUpdateContactInfoMutation>;
export type UpdateContactInfoMutationResult = Apollo.MutationResult<UpdateContactInfoMutation>;
export type UpdateContactInfoMutationOptions = Apollo.BaseMutationOptions<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>;
export const CreateContactInfoDocument = gql`
    mutation CreateContactInfo($input: CreateContactInfoInput!) {
  createContactInfo(input: $input)
}
    `;
export type CreateContactInfoMutationFn = Apollo.MutationFunction<CreateContactInfoMutation, CreateContactInfoMutationVariables>;

/**
 * __useCreateContactInfoMutation__
 *
 * To run a mutation, you first call `useCreateContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactInfoMutation, { data, loading, error }] = useCreateContactInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContactInfoMutation, CreateContactInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateContactInfoMutation, CreateContactInfoMutationVariables>(CreateContactInfoDocument, options);
      }
export type CreateContactInfoMutationHookResult = ReturnType<typeof useCreateContactInfoMutation>;
export type CreateContactInfoMutationResult = Apollo.MutationResult<CreateContactInfoMutation>;
export type CreateContactInfoMutationOptions = Apollo.BaseMutationOptions<CreateContactInfoMutation, CreateContactInfoMutationVariables>;
export const GetUserDocument = gql`
    query GetUser($id: String!) {
  getUser(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables?: GetUserQueryVariables) {
      return { query: GetUserDocument, variables: variables }
    }
export const SearchUsersDocument = gql`
    query SearchUsers($input: SearchUserInput!) {
  searchUsers(input: $input) {
    ...UserSearchResult
  }
}
    ${UserSearchResultFragmentDoc}`;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export function refetchSearchUsersQuery(variables?: SearchUsersQueryVariables) {
      return { query: SearchUsersDocument, variables: variables }
    }
export const GetAllMunicipalitiesDocument = gql`
    query GetAllMunicipalities {
  getAllMunicipalities
}
    `;

/**
 * __useGetAllMunicipalitiesQuery__
 *
 * To run a query within a React component, call `useGetAllMunicipalitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMunicipalitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMunicipalitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMunicipalitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllMunicipalitiesQuery, GetAllMunicipalitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllMunicipalitiesQuery, GetAllMunicipalitiesQueryVariables>(GetAllMunicipalitiesDocument, options);
      }
export function useGetAllMunicipalitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllMunicipalitiesQuery, GetAllMunicipalitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllMunicipalitiesQuery, GetAllMunicipalitiesQueryVariables>(GetAllMunicipalitiesDocument, options);
        }
export type GetAllMunicipalitiesQueryHookResult = ReturnType<typeof useGetAllMunicipalitiesQuery>;
export type GetAllMunicipalitiesLazyQueryHookResult = ReturnType<typeof useGetAllMunicipalitiesLazyQuery>;
export type GetAllMunicipalitiesQueryResult = Apollo.QueryResult<GetAllMunicipalitiesQuery, GetAllMunicipalitiesQueryVariables>;
export function refetchGetAllMunicipalitiesQuery(variables?: GetAllMunicipalitiesQueryVariables) {
      return { query: GetAllMunicipalitiesDocument, variables: variables }
    }
export const UpdatePermissionsDocument = gql`
    mutation UpdatePermissions($input: PermissionsInput!) {
  updatePermissions(input: $input) {
    _id
    permissions {
      municipalities
    }
  }
}
    `;
export type UpdatePermissionsMutationFn = Apollo.MutationFunction<UpdatePermissionsMutation, UpdatePermissionsMutationVariables>;

/**
 * __useUpdatePermissionsMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionsMutation, { data, loading, error }] = useUpdatePermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePermissionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePermissionsMutation, UpdatePermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePermissionsMutation, UpdatePermissionsMutationVariables>(UpdatePermissionsDocument, options);
      }
export type UpdatePermissionsMutationHookResult = ReturnType<typeof useUpdatePermissionsMutation>;
export type UpdatePermissionsMutationResult = Apollo.MutationResult<UpdatePermissionsMutation>;
export type UpdatePermissionsMutationOptions = Apollo.BaseMutationOptions<UpdatePermissionsMutation, UpdatePermissionsMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    