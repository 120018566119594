import * as React from "react"
import { RoleEnum, useGetAllMunicipalitiesQuery, User, useUpdateRoleMutation } from "../codegen/graphql"
import {
  CssBaseline,
  MenuItem,
  TextField,
  Tooltip,
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
  Divider,
  Typography,
  Box,
} from "@mui/material"
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material"
import { useSnackbar } from "../hooks/useSnackbarHook"

interface Props {
  user: User
}

function not(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a: string[], b: string[]) {
  return [...a, ...not(b, a)]
}

export default function PermissionsForm({ user }: Props) {
  const { setSnackbarState } = useSnackbar()
  const { data } = useGetAllMunicipalitiesQuery({
    onError: (e) => {
      setSnackbarState({ message: e.message, type: "error" })
    },
  })
  const [updateRole] = useUpdateRoleMutation({
    onError: (e) => {
      setSnackbarState({ message: "Error updating role: " + e.message, type: "error" })
    },
    onCompleted: (res) => {
      setSnackbarState({ message: "Role set to: " + res.updateRole.role, type: "info" })
    },
  })

  const [checked, setChecked] = React.useState<string[]>([])
  const [left, setLeft] = React.useState(data?.getAllMunicipalities || [])
  const [right, setRight] = React.useState<string[]>([])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleChangeRole = (
    event: React.ChangeEvent<HTMLInputElement | (HTMLTextAreaElement & { target: { value: RoleEnum } })>
  ) => {
    const payload = { _id: user._id, role: event.target.value as RoleEnum }
    console.log("Payload: ", payload)
    updateRole({ variables: { input: payload } })
  }

  const numberOfChecked = (items: string[]) => intersection(checked, items).length

  const handleToggleAll = (items: string[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const customList = (title: string, items: string[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`

          return (
            <ListItem key={value} id={value} role="listitem" button onClick={handleToggle(value)}>
              <Checkbox
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  "aria-labelledby": labelId,
                }}
              />
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Card>
  )

  return (
    <Box sx={{ padding: "10px", marginTop: "20px", border: "3px solid red" }}>
      <Box
        sx={{
          // position: "absolute",
          marginTop: "-25px",
          marginLeft: "10px",
          color: "red",
          borderStyle: "solid",
          background: "white",
          borderRadius: "10px",
          borderWidth: "2px",
          borderColor: "red",
          padding: "2px 10px",
          width: "fit-content",
        }}>
        Danger Zone
      </Box>
      <CssBaseline />
      <Typography variant="h6" gutterBottom paddingTop="1rem">
        Rättigheter
      </Typography>

      <Grid container spacing={3} justifyContent="left" alignItems="center">
        <Grid item xs={12} sm={6} display="flex">
          <TextField
            // disabled={!isEdit}
            // required
            id="role"
            name="role"
            label={"Roller"}
            fullWidth
            select
            autoComplete="role"
            value={user.role}
            onChange={handleChangeRole}>
            {[RoleEnum.User, RoleEnum.Admin, RoleEnum.Superadmin].map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>
          <Tooltip title="Rollen 'User' är default och ger användaren rättigheter att endast se och ändra sin egen information. Rollen 'Admin' gör att användaren kan ges rättigheter att se och editera andra medlemmar baserat på kommun. Rollen 'SuperAdmin' ger användaren fullständiga rättigheter att se och editera allt, inklusive andra användares roller.">
            <HelpOutlineIcon sx={{ margin: "auto", paddingLeft: "0.1rem" }} />
          </Tooltip>
        </Grid>
        {(user.role === RoleEnum.Admin || user.role === RoleEnum.Superadmin) && (
          <React.Fragment>
            <Grid item margin={"auto"} marginBottom={2}>
              <Typography variant="caption" gutterBottom>
                {`Rättigheter för ${user.contactInfo?.firstname || ""} ${user.contactInfo?.lastname || ""} (${
                  user.email
                }) att interagera med medlemsdatabasen`}
              </Typography>
            </Grid>
            <Grid container spacing={4} justifyContent="center">
              <Grid item id="choices-left">
                {customList("Choices", left)}
              </Grid>
              <Grid item justifyContent="center">
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right">
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left">
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item id="choices-right">
                {customList("Chosen", right)}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Box>
  )
}
