import { createContext, useContext, useState } from "react"

export type SnackbarMessage = "error" | "info"
export type SnackbarPayload = { message: string; type: SnackbarMessage }

interface Props {
  children: React.ReactNode
}

// This component handles the snackbar state.
// The visual snackbar component is separated from this component.

// 1. Create context, i.e a globally accesible state
// On initial creation this is an empty state.
const snackbarContext = createContext<ReturnType<typeof useProvideSnackbar>>({
  snackbarState: { message: "", type: "info" },
  setSnackbarState: () => {},
})

// 2. Create a function to hold the logic for the snackbar state hook.
function useProvideSnackbar() {
  const [snackbarState, setSnackbarState] = useState<SnackbarPayload>({ message: "", type: "error" })

  return {
    snackbarState,
    setSnackbarState,
  }
}

// 3. Make a SnackbarProvider that will take a child, load the snackbarContext with the snackbar state,
//    and make it  accessible to the child component.
//    The SnackbarProvider is a component that wraps the </App> component.
//Add a provider to the snackbarContext just created, i.e load the useSnackbar hook to snackbarContext to make it available
export function SnackbarProvider({ children }: Props) {
  const snackbar = useProvideSnackbar()
  return <snackbarContext.Provider value={snackbar}>{children}</snackbarContext.Provider>
}

// 4. Create a hook for the Snackbar component to use
export const useSnackbar = () => {
  return useContext(snackbarContext)
}
