import { CircularProgress, Grid } from "@mui/material"
import { useMemo } from "react"
import GoogleSignin from "./GoogleSignin"
import { useAuth } from "../hooks/useAuthHook"
import PasswordSignIn from "./PasswordSignIn"

const SignInPage = () => {
  const auth = useAuth()
  const backgroundImageUrl = useMemo(() => {
    return `url(/images/Ek${Math.round(Math.random() * 11 + 1)}.jpg)`
  }, [])

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        container
        item
        flexDirection="row-reverse"
        xs={12}
        sm={12}
        md={12}
        sx={{
          backgroundImage: backgroundImageUrl,
          backgroundRepeat: "no-repeat",
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "bottom right",
        }}>
        <Grid item margin="2rem">
          <GoogleSignin />

          {process.env.REACT_APP_IS_LOCAL ? <PasswordSignIn /> : <></>}
        </Grid>
        <Grid
          position="absolute"
          marginLeft="auto"
          marginRight="auto"
          marginTop="20%"
          left="0"
          right="0"
          textAlign="center">
          {auth?.loginLoading ? <CircularProgress size="5rem" /> : <></>}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignInPage
