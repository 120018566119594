import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { CircularProgress } from "@mui/material"

import { useEffect } from "react"
import "./App.css"
import { RoleEnum } from "./codegen/graphql"
import Dashboard from "./components/Dashboard"
import SignIn from "./components/Signin"
import { useAuth } from "./hooks/useAuthHook"
import { useView } from "./hooks/useViewHook"
function App() {
  const auth = useAuth()
  const view = useView()
  const setView = view.setView
  useEffect(() => {
    if (!auth?.user?._id) {
      setView("signIn")
      return
    }

    if (auth?.user?.role && [RoleEnum.Admin, RoleEnum.Superadmin].includes(auth.user.role)) {
      setView("members")
    } else {
      setView("profile")
    }
  }, [auth?.user?._id, auth?.user?.role, setView])

  return localStorage.getItem("MEDAuthToken") && auth?.loadingSession ? (
    <CircularProgress size="5rem" />
  ) : view.viewLabel !== "signIn" ? (
    <Dashboard children={view.viewElement} />
  ) : (
    <SignIn />
  )
}

export default App
