import { Snackbar, Alert } from "@mui/material"
import { useSnackbar } from "../hooks/useSnackbarHook"

export const GlobalSnackbar = () => {
  const { snackbarState, setSnackbarState } = useSnackbar()

  return (
    <Snackbar
      open={!!snackbarState?.message}
      autoHideDuration={3500}
      onClose={(event, reason) => {
        if (reason === "clickaway") return

        setSnackbarState({ message: "", type: "info" })
      }}
      // defaultValue={'Default...'}
      message={snackbarState?.message}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}>
      <Alert elevation={6} variant="filled" severity={snackbarState.type}>
        {snackbarState?.message}
      </Alert>
    </Snackbar>
  )
}
