import React, { useState, useMemo } from "react"
import { styled } from "@mui/system"
import {
  CssBaseline,
  Drawer as MuiDrawer,
  Box,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  Container,
  Button,
  Typography,
  AppBarProps as MuiAppBarProps,
} from "@mui/material"
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Launch as LaunchIcon,
} from "@mui/icons-material"

import { mainListItems, secondaryListItems } from "./ListItems"

import LogoutButton from "./Logout"
import { useAuth } from "../hooks/useAuthHook"
import config from "../config"
import { useView } from "../hooks/useViewHook"
import { RoleEnum } from "../codegen/graphql"

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
  drawerwidth: number
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, drawerwidth }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  // transition: theme.transitions.create(["width", "margin"], {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  // ...(open && {
  //   marginLeft: drawerwidth,
  //   width: `calc(100% - ${drawerwidth}px)`,
  //   // transition: theme.transitions.create(["width", "margin"], {
  //   //   easing: theme.transitions.easing.sharp,
  //   //   duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      // transition: theme.transitions.create("width", {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}))
interface Props {
  children: React.ReactNode
}
export default function DashboardContent({ children }: Props) {
  const auth = useAuth()
  const view = useView()
  const isAdmin = useMemo(() => {
    return auth?.user?.role === RoleEnum.Superadmin || auth?.user?.role === RoleEnum.Admin
  }, [auth?.user?.role])
  const [open, setOpen] = useState(false)

  const toggleDrawer = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpen(!open)
  }
  const drawerWidth: number = isAdmin ? 240 : 0

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open} drawerwidth={drawerWidth}>
        <Toolbar
          sx={{
            paddingRight: "24px", // keep right padding when drawer closed
            justifyContent: "flex-start",
          }}>
          {isAdmin && (
            <Box sx={{ marginRight: "auto" }}>
              <Button
                sx={{ color: "primary.contrastText" }}
                onClick={() => {
                  view.setView("members")
                }}>
                Member List
              </Button>

              {!config.isProduction && (
                <>
                  <Button sx={{ color: "primary.contrastText" }} href={"http://localhost:8080"} target="_blank">
                    Traefik
                    <LaunchIcon sx={{ marginLeft: "0.2rem" }} />
                  </Button>
                  <Button sx={{ color: "primary.contrastText" }} href={"http://localhost:8888"} target="_blank">
                    Mongo-express
                    <LaunchIcon sx={{ marginLeft: "0.2rem" }} />
                  </Button>
                </>
              )}
            </Box>
          )}
          <Box sx={{ marginLeft: "auto", display: "flex", color: "secondary.contrastText" }}>
            <Typography marginY="auto" sx={{ color: "grey.400" }}>
              {`${auth?.user?.contactInfo?.firstname || ""} ${auth?.user?.contactInfo?.lastname || ""} | ${
                auth?.user?.email || ""
              }`}
            </Typography>

            {auth?.user?.role && (
              <Button sx={{ color: "primary.contrastText" }} onClick={() => view.setView("profile")}>
                Min Profil
              </Button>
            )}
            <LogoutButton />
          </Box>
        </Toolbar>
      </AppBar>
      {isAdmin && (
        <Drawer variant="permanent" open={open} sx={{ marginTop: "64px" }}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}>
            <IconButton onClick={(e) => toggleDrawer(e)}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Toolbar>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>{secondaryListItems}</List>
        </Drawer>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}>
        <Toolbar />
        <Container id="main-container" maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <>{children}</>
        </Container>
      </Box>
    </Box>
  )
}
