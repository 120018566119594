import { Fragment } from "react"
import { Typography, List, ListItem } from "@mui/material"
const GDPRPolicy = () => (
  <Fragment>
    <Typography variant="h6">
      <b>Välkommen till Medborgerlig Samlings integritetspolicy.</b>
      <br />
    </Typography>
    <Typography>
      När du nyttjar Medborgerlig Samlings tjänster så tillåter du partiet att använda sig av din personliga
      information. Den här policyn är tänkt att hjälpa dig att förstå vilken data vi samlar, varför vi samlar den och
      vad vi gör med den. För frågor gällande denna policy, maila: <br />
      <a href="mailto:privacy@medborgerligsamling.se">privacy@medborgerligsamling.se</a>.
    </Typography>
    <Typography>
      <br />
      <b>Personuppgiftssäkerhet.</b>
    </Typography>
    <Typography>
      Vi använder din information i flera av våra system och tjänster. Dels för att du ska kommunicera med andra
      medlemmar, för att du ska kunna knyta kontakter och att vi ska ha ett register över de medlemmar vi har. Vi lagrar
      även personligt identifierbar information av säkerhetsskäl, till exempel för att se vem som gjort vad i forum, vem
      som har kopplat upp sig mot vilka system, och så vidare.
    </Typography>
    <Typography>Den här policyn kommer att förklara:</Typography>
    <List>
      <ListItem>
        <Typography variant="body1">Vilken information vi samlar in och varför vi gör det.</Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">Hur vi använder informationen.</Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">Dina rättigheter för att komma åt och uppdatera informationen.</Typography>
      </ListItem>
    </List>
    <Typography>
      Vi har försökt att skriva policyn så tydligt och lättläst som möjligt. Skulle du ha några ytterligare frågor om
      policyn kan du maila: <br />
      <a href="mailto:privacy@medborgerligsamling.se">privacy@medborgerligsamling.se</a>.
    </Typography>
    <Typography>
      <br />
      <b>Information partiet samlar in.</b>
    </Typography>
    <Typography>Partiet samlar in information om dig för att kunna bedriva partiets verksamhet.</Typography>
    <Typography>Partiet samlar in information på följande sätt:</Typography>
    <br />
    En komplett lista över vilka kategorier av personuppgifter partiet behandlar kan lämnas ut på begäran.
    <List>
      <ListItem>
        <b>Information du aktivt ger partiet.</b>
      </ListItem>
      <ListItem>
        <Typography variant="body1">
          Den information du givit partiet i samband med tecknandet av ditt medlemskap, såsom förnamn, efternamn,
          mailadress, gatuadress, stad, kommun, land, &nbsp;postnummer och telefonnummer.
          <br />
          Detta inkluderar information som du själv skriver in eller laddar upp i partiets system. Det kan t.ex. vara
          bilder, kontaktuppgifter och videos men även politiska åsikter.
        </Typography>
      </ListItem>
      <ListItem>
        <Typography variant="body1">
          Information som samlas in vid användande av system.
          <br />
          Partiet samlar in olika information beroende på vilket system du ansluter till.
          <br />
          En komplett lista över vilka system och vilka kategorier som lagras kan lämnas ut på begäran.
          <br />
          Information som samlas in är bland annat.
        </Typography>
      </ListItem>
    </List>
    <List>
      <ListItem>
        <Typography variant="body1">
          Loggning av tjänsters användande.
          <br />
          När du använder partiets tjänster loggas automatiskt allting du gör i partiet system. Detta inkluderar:
        </Typography>
      </ListItem>
    </List>
    <List>
      <ListItem>
        <Typography variant="body1">Internet Protokoll Adress (IP-adress)</Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">Kakor som personligen identifierar din webbläsare.</Typography>
      </ListItem>
    </List>
    <List>
      <ListItem>
        <Typography variant="body1">
          <b>Lagringsytor. </b>
          <br />
          Vid användande av partiets lagringsytor behandlas filerna och dess metadata, (vilket innefattar
          personuppgifter), av partiet eller dess tredjepartsleverantörer.
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <b>Kakor. </b>
          <br />
          Vid användande av vissa av partiets tjänster kan din personliga information lagras i en kaka i din webbläsare.
          <br />
          För information gällande kakor läs partiets kak-policy: <br />
          <a href="http://medborgerligsamling.se/cookie-policy/">här</a>.
        </Typography>
      </ListItem>
    </List>
    <Typography>&nbsp;</Typography>
    <Typography>
      <b>Hur partiet använder din personliga information.</b>
    </Typography>
    <Typography>
      Behandling av personuppgifter ska endast ske där partiet har användning för personuppgifterna enligt
      bestämmelserna i dataskyddsförordningen.
      <br />
      Partiet behandlar personuppgifter på flera olika nivåer beroende på vad du har för roll inom partiet. En person
      kan tillhöra en eller flera roller.
    </Typography>
    <Typography>De roller som partiet samlar in personuppgifter om är följande:</Typography>
    <List>
      <ListItem>
        <Typography variant="body1">
          <b>Övriga externa personer</b>
          <br />
          Partiet samlar ibland in vissa personuppgifter från externa personer i de fall där de önskar att interagera
          med partiets olika plattformar. Till exempel MEDBloggen.
          <br />
          Dessa uppgifter används för att erbjuda bra service i tjänsten den registrerade väljer att interagera med.
          <br />
          Personuppgifterna vi samlar in är till exempel förnamn, efternamn, mailadress.
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <b>Obligationsägare</b>
          <br />
          Partiet samlar i vissa sällsynta fall in personuppgifter om externa personer som köpt partiobligationer.
          <br />
          Personuppgifterna vi samlar in är förnamn, efternamn, personnummer/organisationsnummer, gatuadress,
          postnummer, ort, epostadress, telefonnummer.
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <b>Medlem</b>
          <br />
          Denna kategori är varje person som har valt att gå med i partiet.
          <br />
          Partiet behandlar varje medlems förnamn, efternamn, e-post och telefonnummer.
          <br />
          Dessa uppgifter används för att partiet ska kunna kommunicera med medlemmen genom nyhetsbrev och andra verktyg
          partiet använder.
          <br />
          Partiet behandlar även land, stad, kommun, gatuadress och postnummer för att &nbsp;kunna placera medlemmen
          till rätt underdistrikt och som underlag för arbetet hos valberedningen och nommineringskommitén.
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <b>Partiarbetare</b>
          <br />
          Denna kategori är varje person som utför någon form av arbete för partiet, t.ex. de personer som driver
          IT-organisationen eller marknadsorganisationen.
          <br />
          Utöver de uppgifter som behandlas för medlemmar så behandlas även nya mailadresser som används för arbete inom
          partiet samt metadata på filer som lagras i partiets gemensamma lagringsyta och andra system.
          <br />
          Partiarbetares kontaktuppgifter kan även kommuniceras ut till partiets medlemmar där det är nödvändigt för att
          de ska kunna utföra sina uppgifter.
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <Typography variant="body1">
            <b>Förtroendevald</b>
            <br />
            Denna kategori är varje person som agerar som representant för partiet i olika frågor. T.ex. valberedningen,
            styrelsen eller politiska talespersoner.
            <br />
            Partiet behandlar samma information som för partiarbetare och medlemmar med tillägget personnummer. Dessutom
            behandlar även partiet bilder, filmer och ljudklipp på de förtroendevalda som kan publiceras på partiets
            hemsida, bloggar och andra kommunikativa system.
            <br />
            Anledningen till denna behandling är för att partiet ska kunna bedriva marknadsföring och vara mer synliga
            gentemot media och potentiella väljare.
            <br />
            Förtroendevaldas kontaktuppgifter kan även kommuniceras utanför partiet för att underlätta för medier att ta
            kontakt med rätt person i partiet för intervjuer och dylikt.
          </Typography>
        </Typography>
      </ListItem>
    </List>
    <Typography>
      <b>Lagring av politiska åsikter.</b>
    </Typography>
    <Typography>
      I partiets fora, chattar, epost och andra system där användarna själv bidrar med innehåll så kan det förekomma
      politiska åsikter. Partiet lagrar inte sina medlemmars politiska åsikter på andra platser eller i andra system än
      där medlemmen själv har skrivit dom utan medlemmens godkännande.
    </Typography>
    <Typography>
      <br />
      <b>Information du delar.</b>
    </Typography>
    <Typography>
      I partiets system så har du möjlighet att dela ytterligare information med andra medlemmar och personer utanför
      partiet. När du delar information i partiets kanaler, tänk på att detta är synligt för andra medlemmar och
      administratörer samt i vissa fall tredjepartsleverantörer.
      <br />
      En komplett lista över tredjepartsleverantörer kan lämnas ut på begäran.
    </Typography>
    <Typography>
      <br />
      <b>Rättigheter.</b>
    </Typography>
    <Typography>Enligt dataskyddsförordningen har du rätt till…</Typography>
    <List>
      <ListItem>
        <Typography variant="body1">
          <a href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/de-registrerades-rattigheter/ratt-till-information/">
            Information gällande insamling av dina personuppgifter.
          </a>
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <a href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/de-registrerades-rattigheter/ratt-till-rattelse/">
            Få felaktiga personuppgifter rättade.
          </a>
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <a href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/de-registrerades-rattigheter/ratt-till-radering/">
            Få dina personuppgifter borttagna där detta är möjligt förenligt med svensk lag.
          </a>
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <a href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/de-registrerades-rattigheter/ratt-till-begransning-av-behandling/">
            I vissa fall begränsa behandlingen av personuppgifter.
          </a>
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <a href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/de-registrerades-rattigheter/dataportabilitet/">
            Migrera dina personuppgifter till en annan tjänst.
          </a>
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <a href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/de-registrerades-rattigheter/ratt-att-gora-invandningar/">
            Bestrida anledningar till behandlingen av personuppgifter.
          </a>
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <a href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/de-registrerades-rattigheter/automatiserad-behandling/">
            Att, utom i vissa fall, inte drabbas av automatiserat beslutsfattande gällande insamlande av
            personuppgifter.
          </a>
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <a href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/de-registrerades-rattigheter/klagomal/">
            Att lämna klagomål till tillsynsmyndigheten.
          </a>
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <a href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/de-registrerades-rattigheter/skadestand/">
            Skadestånd i de fall där behandlingen av personuppgifter har skett felaktigt och det har lett till skada för
            den registrerade.
          </a>
        </Typography>
      </ListItem>
    </List>
    <Typography>
      För mer information gällande dina rättigheter som registrerad kan du hitta
      <a href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/de-registrerades-rattigheter/">
        här
      </a>
      .
    </Typography>
    <Typography>
      <b>Åtkomst till din information och ändringar av din persondata.</b>
      <br />
    </Typography>
    <Typography>
      För åtkomst till din information vänligen kontakta: <br />
      <a href="mailto:privacy@medborgerligsamling.se">privacy@medborgerligsamling.se</a>.
    </Typography>
    <Typography>
      Eftersom partiet drivs ideellt kan vissa ledtider förekomma på sammanställande av informationen.
    </Typography>
    <Typography>
      Partiet har som mål att endast behålla information som är relevant för partiet. Partiet jobbar kontinuerligt med
      att rensa och ta bort information som partiet inte har anledning att behålla.
      <br />
      För att skydda från felaktigt borttagande så kan viss information ligga kvar i säkerhetskopior och i loggar för
      att skydda både partiet och din information.
    </Typography>
    <Typography>
      <br />
      <b>Vilka har åtkomst till din information.</b>
    </Typography>
    <Typography>
      Din information är åtkomlig av olika funktioner och personer inom partiet. I de fall ett system hanteras av tredje
      part kan även denna ha tillgång till din personliga information.
    </Typography>
    <Typography>Dessa funktioner har tillgång till din information:</Typography>
    <List>
      <ListItem>
        <Typography variant="body1">
          <b>
            Administratörer.
            <br />
            Detta skiljer sig från system till system och kan lämnas ut på begäran.
            <br />
            Administratörer kan komma åt kommentarer, epost, personprofiler, fotografier och annan personligt
            identifierbar information.
          </b>
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <strong>
            Moderatorer.
            <br />
          </strong>
          Detta skiljer sig från administratörsfunktionen då moderatorer har mindre tillgång till persondata än
          administratörer. De kan däremot fortfarande se kommentarer, trådar med mera, på partiets forum. Även i de fall
          tråden eller kommentaren har dolts kan moderatorer se dem.
          <br />
          Moderatorer har även tillgång till kommentarer i modereringsverktyg och andra verktyg som krävs i deras roll.
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <strong>Medlemsansvarig.</strong>
          <br />
          Dessa personer har tillgång till medlemsregistret och bearbetar den informationen.
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">
          <strong>
            Medlemmar.
            <br />
          </strong>
          I vissa fall har medlemmar tillgång till personligt identifierbar information, till exempel namn och epost, i
          de fall sådan information är synlig i partiets system.
          <br />
          Medlemmar har även möjlighet att se sådant som postas i partiets fora, chattar och andra system.
        </Typography>
      </ListItem>
    </List>
    <Typography>
      <b>Informationssäkerhet.</b>
    </Typography>
    <Typography>
      Partiet försöker att hålla så hög säkerhet som skäligen är möjligt för din persondata. För att skydda din
      persondata har partiet vidtagit bland annat dessa åtgärder:
    </Typography>
    <List>
      <ListItem>
        <Typography variant="body1">Krypterar åtkomsten till våra tjänster med SSL.</Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">Tvingande användning av tvåstegsautentisiering där det är möjligt.</Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">Återkommande automatiska backuper av partiets system.</Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">Loggning av aktivitet på partiets system.</Typography>
      </ListItem>

      <ListItem>
        <Typography variant="body1">Begränsar åtkomsten till din persondata i högsta skäliga mån.</Typography>
      </ListItem>
    </List>
    <Typography>
      <b>Ändringar.</b>
    </Typography>
    <Typography>
      Partiet kan komma att göra ändringar i framtiden. Dessa ändringar ska generellt sett inte sänka dina
      personuppgifters säkerhet och/eller dina rättigheter. I de fall där nivån för säkerhet eller rättigheter sänks så
      får du chans att godkänna de nya villkoren.
    </Typography>
    <Typography>
      De ändringar som sker kommer att göras i det här dokumentet. Vid större ändringar så kan partiet skicka ut notis
      till medlemmarna via e-post gällande den nya policyn.
    </Typography>
    <Typography>
      <br />
      <b>Dataskyddsombud</b>
    </Typography>
    <Typography>
      För frågor och yrkanden gällande denna policy eller dina personuppgifter kan du vända dig till partiets
      dataskyddsombud.
    </Typography>
    <Typography>Tage Borg</Typography>
    <Typography>
      <a href="mailto:privacy@medborgerligsamling.se">privacy@medborgerligsamling.se</a>
    </Typography>
  </Fragment>
)
export default GDPRPolicy
