import { Button } from "@mui/material"
import { useEffect } from "react"
import { useAuth } from "../hooks/useAuthHook"

export default function GoogleSignin() {
  const auth = useAuth()

  useEffect(() => {
    if (auth?.user?._id || !auth?.handleGoogleSignIn || !auth?.initializeGsi) return

    const script = document.createElement("script")
    script.src = "https://accounts.google.com/gsi/client"
    script.onload = auth.initializeGsi
    script.async = true
    script.id = "google-client-script"
    document.querySelector("body")?.appendChild(script)
    return () => {
      window.google?.accounts.id.cancel()
      document.getElementById("google-client-script")?.remove()
    }
  }, [auth?.handleGoogleSignIn, auth?.initializeGsi, auth?.user?._id])

  return <Button className={"g_id_signin"} />
}
