import { useCallback } from "react"
import { createContext, useContext, useState } from "react"
import MemberList from "../components/MembersList"
import ProfileForm from "../components/ProfileForm"
import SignIn from "../components/Signin"

export const viewLabels = ["signIn", "profile", "members"] as const
export type ViewLabel = typeof viewLabels[number]

const viewElements: Record<ViewLabel, JSX.Element> = {
  signIn: <SignIn />,
  profile: <ProfileForm />,
  members: <MemberList />,
}

const viewContext = createContext<ReturnType<typeof useProvideView>>({
  viewLabel: viewLabels[0],
  viewElement: viewElements["signIn"],
  setView: () => {},
})

interface Props {
  children: React.ReactNode | undefined
}
export function ViewProvider({ children }: Props) {
  const view = useProvideView()

  return <viewContext.Provider value={view}>{children}</viewContext.Provider>
}

export const useView = () => {
  return useContext(viewContext)
}

function useProvideView() {
  const [viewLabel, setViewLabel] = useState<ViewLabel>("signIn")
  const [viewElement, setViewElement] = useState<JSX.Element | undefined>(viewElements["signIn"])

  const setView = useCallback((viewLabel: ViewLabel, userId?: string): void => {
    if (viewLabel === "profile" && userId) {
      setViewElement(<ProfileForm userId={userId} />)
      setViewLabel(viewLabel)
    } else {
      setViewElement(viewElements[viewLabel])
      setViewLabel(viewLabel)
    }
  }, [])

  return {
    viewLabel,
    viewElement,
    setView,
  }
}
