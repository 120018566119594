import { useEffect } from "react"
import { createContext, useContext, useState } from "react"
import { useLoginMutation, usePasswordLoginMutation, User, useSessionLoginQuery } from "../codegen/graphql"
import { createAndSetCookie } from "../utils"
import { useSnackbar } from "./useSnackbarHook"

const GOOGLE_CLIENT_ID = "185860662362-jdlfdvhfcpfbku7tv8r7klkppgprmf8k.apps.googleusercontent.com"

const COOKIE_EXPIRE_HOURS = 24
const authContext = createContext<ReturnType<typeof useProvideAuth> | undefined>(undefined)

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
interface Props {
  children: React.ReactNode
}
export function AuthProvider({ children }: Props) {
  const auth = useProvideAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)
  const {
    data: sessionData,
    loading: loadingSession,
    error: sessionError,
  } = useSessionLoginQuery({
    variables: { token: localStorage.getItem("MEDAuthToken") || "" },
    skip: !localStorage.getItem("MEDAuthToken"),
  })
  const { setSnackbarState } = useSnackbar()

  const [user, setUser] = useState<User | undefined | null>(sessionData?.sessionLogin.user)
  const [loginMutation, { loading, error }] = useLoginMutation({
    onError: (e) => {
      setSnackbarState({ message: e.message, type: "error" })
    },
  })

  const [passwordLoginMutation, { loading: passwordLoginLoading, error: passwordLoginError }] =
    usePasswordLoginMutation({
      onError: (e) => {
        setSnackbarState({ message: e.message, type: "error" })
      },
    })

  useEffect(() => {
    if (!user && sessionData?.sessionLogin.user) {
      setUser(sessionData?.sessionLogin.user)
    }
  }, [sessionData, user])

  const handleGoogleSignIn = (res: CredentialResponse) => {
    if (!res.clientId || !res.credential) return

    const payload = {
      variables: {
        loginClientId: res.clientId,
        loginCredentials: res.credential,
      },
    }

    loginMutation(payload).then((val) => {
      if (!val.data?.login?.sessionToken || !res.credential) return

      localStorage.setItem("MEDAuthToken", val.data.login.sessionToken)
      localStorage.setItem("MEDGoogleAuthToken", res.credential)
      createAndSetCookie("MEDAuthCookie", val.data.login.sessionToken, COOKIE_EXPIRE_HOURS)
      setUser(val.data?.login.user)
    })
  }

  const initializeGsi = () => {
    if (!window.google || gsiScriptLoaded) return

    setGsiScriptLoaded(true)
    window.google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: handleGoogleSignIn,
    })
  }

  const logout = () => {
    // TODO: Track session tokens with graphql server and properly sign out user by setting token to invalid on server side.

    localStorage.removeItem("MEDAuthToken")
    // Remove cookie? if it's not a session cookie.
    setUser(undefined)
  }

  const passwordLogin = (email: string, password: string) => {
    passwordLoginMutation({ variables: { email, password } }).then((res) => {
      if (!res.data?.passwordLogin?.sessionToken) return

      setUser(res.data.passwordLogin.user)
      localStorage.setItem("MEDAuthToken", res.data.passwordLogin.sessionToken)
      //Set cookie

      createAndSetCookie("MEDAuthCookie", res.data.passwordLogin.sessionToken, COOKIE_EXPIRE_HOURS)
    })
  }

  // Return the user object and auth methods
  return {
    user,
    handleGoogleSignIn,
    logout,
    loginLoading: loading,
    loginError: error,
    loadingSession,
    sessionError,
    gsiScriptLoaded,
    initializeGsi,
    passwordLogin,
    passwordLoginLoading,
    passwordLoginError,
  }
}
