import {
  Avatar,
  Button,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  CssBaseline,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Container,
} from "@mui/material"
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material"
import { useAuth } from "../hooks/useAuthHook"
import { useState, FormEvent } from "react"

export default function PasswordSignIn() {
  const auth = useAuth()
  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get("email")
    const password = data.get("password")

    if (typeof email === "string" && typeof password === "string" && email.length && password.length) {
      auth?.passwordLogin(email, password)
    }
  }

  return (
    <Container component="main" maxWidth="xs" style={{ backgroundColor: "white" }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            disabled={auth?.passwordLoginLoading}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={`${showPassword ? "text" : "password"}`}
            id="password"
            autoComplete="current-password"
            disabled={auth?.passwordLoginLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    <Icon>{showPassword ? "visibility" : "visibility_off"}</Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={auth?.passwordLoginLoading}>
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  )
}
