import { ChangeEvent, Fragment, useMemo, useState, useEffect } from "react"
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  FormLabel,
  Modal,
  Paper,
  Button,
} from "@mui/material"
import { Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon } from "@mui/icons-material"
import {
  useGetUserQuery,
  RoleEnum,
  UpdateContactInfoInput,
  useUpdateContactInfoMutation,
  useCreateMembershipMutation,
  CreateMembershipInput,
} from "../codegen/graphql"
import MembershipForm from "./MembershipForm"
import PermissionsForm from "./PermissionsForm"
import { useAuth } from "../hooks/useAuthHook"
import GDPRPolicy from "../utils/GDPRPolicy"
import { useSnackbar } from "../hooks/useSnackbarHook"
import { xor } from "lodash"

const FIRSTNAME = "Förnamn"
const LASTNAME = "Efternamn"
const STREET_ADDRESS = "Gatuadress"
const MUNICIPALITY = "Kommun"
const POSTALCODE = "Postnummer"
const PHONE = "Telefon"
const EMAIL = "Email (not editable yet)"

interface Props {
  userId?: string
}

type ProfileFormLabel = "firstname" | "lastname" | "postalCode" | "streetAddress" | "municipality" | "phone"

export default function ProfileForm({ userId }: Props) {
  const [showGDPR, setShowGDPR] = useState(false)
  const [showDeleteMyInfo, setShowDeleteMyInfo] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const auth = useAuth()
  const { setSnackbarState } = useSnackbar()

  const { data, loading } = useGetUserQuery({
    variables: { id: userId || auth?.user?._id || "" },
    onError: (e) => {
      setSnackbarState({ message: e.message, type: "error" })
    },
    skip: !auth?.user?._id,
  })
  const [updateContactInfo] = useUpdateContactInfoMutation({
    onError: (e) => {
      setSnackbarState({ message: e.message, type: "error" })
    },
  })
  const [createMembership] = useCreateMembershipMutation({
    onError: (e) => {
      setSnackbarState({ message: e.message, type: "error" })
    },
    onCompleted: () => {
      setSnackbarState({ message: "Membership created", type: "info" })
    },
  })

  const { user, initialFormState } = useMemo(() => {
    const user = data?.getUser
    const initialFormValues: Partial<Record<ProfileFormLabel, string> & Record<"GDPR", boolean>> = {
      //The order of these are important because we compare the object before sending update query
      firstname: user?.contactInfo?.firstname || "",
      lastname: user?.contactInfo?.lastname || "",
      streetAddress: user?.contactInfo?.postalCode || "" + user?.contactInfo?.municipality || "",
      postalCode: user?.contactInfo?.postalCode || "",
      municipality: user?.contactInfo?.municipality || "",
      phone: user?.contactInfo?.phone || "",
      GDPR: !!user?.contactInfo?.GDPR,
    }
    return { user, initialFormState: initialFormValues }
  }, [data?.getUser])

  const [formState, setFormState] = useState(initialFormState)

  useEffect(() => {
    setFormState(initialFormState)
  }, [initialFormState])

  const handleSave = () => {
    if (!formState.GDPR) {
      setSnackbarState({ message: "Required field GDPR missing", type: "error" })
      return
    }
    setIsEdit(false)
    handleUpdateUser()
  }

  const handleCancel = () => {
    setIsEdit(false)
    setFormState(initialFormState)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.name === "GDPR" ? !formState.GDPR : event.target.value

    setFormState((oldState) => {
      return {
        ...oldState,
        [event.target.name]: value,
      }
    })
  }

  const handleUpdateUser = () => {
    if (!user?._id) return

    const partialContactInfoPayload: Omit<UpdateContactInfoInput, "_id"> = {
      //The order of these are important because we compare the object before sending update query
      firstname: formState.firstname,
      lastname: formState.lastname,
      streetAddress: formState.streetAddress,
      postalCode: formState.postalCode,
      municipality: formState.municipality,
      phone: formState.phone,
      GDPR: formState.GDPR,
    }

    const formStateDelta = xor(Object.values(formState), Object.values(initialFormState))
    // Only send update if anything in the form has changed.
    if (formStateDelta.length < 1) return

    const contactInfoPayload: UpdateContactInfoInput = {
      userId: user._id,
      ...partialContactInfoPayload,
    }

    updateContactInfo({
      variables: {
        input: contactInfoPayload,
      },
    })
  }

  return !user ? (
    <>
      <CircularProgress />
    </>
  ) : (
    <Container component="main" maxWidth="md" sx={{ marginBottom: 4 }}>
      <Paper
        variant="outlined"
        sx={{
          marginY: { xs: 3, md: 6 },
          padding: { xs: 2, md: 3 },
        }}>
        <CssBaseline />
        <Typography variant="h4" gutterBottom>
          Profil
        </Typography>

        {loading ? (
          <CircularProgress />
        ) : (
          <Fragment>
            <form
              onSubmit={(e) => {
                e.preventDefault()

                console.log("You submitted.. ", e)
              }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={!isEdit}
                    required
                    id="firstname"
                    name="firstname"
                    label={FIRSTNAME}
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={formState.firstname}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={!isEdit}
                    required
                    id="lastname"
                    name="lastname"
                    label={LASTNAME}
                    fullWidth
                    autoComplete="family-name"
                    variant="standard"
                    value={formState.lastname}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={!isEdit}
                    // required
                    id="streetAddress"
                    name="streetAddress"
                    label={STREET_ADDRESS}
                    fullWidth
                    autoComplete="shipping address-line1"
                    variant="standard"
                    value={formState.streetAddress}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={!isEdit}
                    required
                    id="postalCode"
                    name="postalCode"
                    label={POSTALCODE}
                    fullWidth
                    autoComplete="postal-code"
                    variant="standard"
                    value={formState.postalCode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={!isEdit}
                    id="municipality"
                    name="municipality"
                    label={MUNICIPALITY}
                    fullWidth
                    variant="standard"
                    value={formState.municipality || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={!isEdit}
                    // required
                    id="phone"
                    name="phone"
                    label={PHONE}
                    fullWidth
                    autoComplete="phone"
                    variant="standard"
                    value={formState.phone || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/*  TODO: Implement email change with verification!  */}
                  <TextField
                    disabled={auth?.user?.role !== RoleEnum.Superadmin || !isEdit}
                    // required
                    id="email"
                    name="email"
                    label={EMAIL}
                    fullWidth
                    autoComplete="email"
                    variant="standard"
                    value={user?.email}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name="GDPR switch"
                    disabled={!isEdit}
                    control={
                      <Checkbox
                        required
                        color="secondary"
                        name="GDPR"
                        checked={formState.GDPR ?? user.contactInfo?.GDPR ?? false}
                        value={!formState.GDPR ?? !user.contactInfo?.GDPR}
                        onChange={handleChange}
                      />
                    }
                    label="Jag godkänner att mina personuppgifter används"
                  />
                  <FormLabel sx={{ color: "secondary.main", cursor: "pointer" }} onClick={() => setShowGDPR(true)}>
                    GDPR Policy
                  </FormLabel>
                </Grid>
              </Grid>
              {isEdit ? (
                <>
                  <Button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault()
                      handleSave()
                    }}>
                    <SaveIcon />
                    <Typography>Spara</Typography>
                  </Button>
                  <Button
                    onClick={(e) => {
                      handleCancel()
                    }}>
                    <CancelIcon />
                    <Typography>Avbryt</Typography>
                  </Button>
                </>
              ) : (
                <Button
                  onClick={(e) => {
                    setIsEdit(true)
                  }}>
                  <EditIcon />
                  <Typography>Editera profil</Typography>
                </Button>
              )}
            </form>
            {auth?.user?.role === RoleEnum.Superadmin && user?.membership ? (
              <Fragment>
                <hr />
                <MembershipForm membership={user.membership} userId={user._id} />
                <hr />
                <PermissionsForm user={user} />
              </Fragment>
            ) : auth?.user?.role === RoleEnum.Superadmin ? (
              <>
                <hr />
                <Button
                  onClick={() => {
                    const oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                    const createMembershipPayload: CreateMembershipInput = {
                      userId: user._id,
                      expireAt: oneYearFromNow,
                      type: "Member",
                    }
                    createMembership({ variables: { input: createMembershipPayload } })
                  }}>
                  Add Membership
                </Button>
              </>
            ) : (
              <></>
            )}
          </Fragment>
        )}
      </Paper>
      <Modal
        open={showGDPR}
        onClose={() => {
          setShowGDPR(false)
        }}
        aria-labelledby="GDPR-Policy"
        aria-describedby="Hur dina personuppgifter används.">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
            scrollBehavior: "smooth",
          }}>
          <Typography id="modal-modal-title" variant="h6" component="div">
            GDPR Policy
            <GDPRPolicy />
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={showDeleteMyInfo}
        onClose={() => {
          setShowDeleteMyInfo(false)
        }}
        aria-labelledby="Radera mina personuppgifter"
        aria-describedby="Vi värnar om din integritet och tar bort alla dina uppgifter om du vill.">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
            scrollBehavior: "smooth",
          }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Test
            {/* <GDPRPolicy /> */}
          </Typography>
        </Box>
      </Modal>
    </Container>
  )
}
