import { Button } from "@mui/material"
import { useAuth } from "../hooks/useAuthHook"

export default function LogoutButton() {
  const auth = useAuth()
  const handleLogout = () => {
    window?.google?.accounts.id.disableAutoSelect()
    auth?.logout()
  }

  return (
    <Button sx={{ color: "primary.contrastText" }} className="g_id_signout" onClick={handleLogout}>
      Logout
    </Button>
  )
}
