import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { AuthProvider } from "./hooks/useAuthHook"
import { ViewProvider } from "./hooks/useViewHook"
import { SnackbarProvider } from "./hooks/useSnackbarHook"
import { GlobalSnackbar } from "./components/GlobalSnackbar"
import { customThemeOverride } from "./theme/themeOverride"
import { ThemeProvider } from "@mui/material/styles"

const GRAPHQL_URI = process.env.NODE_ENV !== "production" ? "http://localhost:4000/graphql" : "/graphql" // TODO: Check if we should just use /graphql for all environments..

const httpLink = createHttpLink({ uri: GRAPHQL_URI })

const authLink = setContext((operation, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem("MEDAuthToken")
  // return the headers to the context so httpLink can read them

  if (token) {
    try {
      const tokenDecodablePart = token?.split(".")[1] || ""
      const decoded = JSON.parse(Buffer.from(tokenDecodablePart, "base64").toString())

      if (decoded && decoded.exp && new Date(decoded.exp * 1000) < new Date()) {
        // Token expired. Remove it from local storage and header.
        localStorage.removeItem("MEDAuthToken")
        token = ""
      }
    } catch (e) {
      console.log("Error inside token decoding: ", e)
    }
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={customThemeOverride}>
      <SnackbarProvider>
        <GlobalSnackbar />
        <ViewProvider>
          <ApolloProvider client={client}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ApolloProvider>
        </ViewProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
