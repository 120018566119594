import * as React from "react"
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Autocomplete,
  CircularProgress,
  IconButton,
  TextField,
  Box,
} from "@mui/material"
import { Launch as LaunchIcon, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"

import { useGetAllMunicipalitiesQuery, useSearchUsersQuery } from "../codegen/graphql"
import { useView } from "../hooks/useViewHook"
import { Fragment, useState } from "react"
import { SortDirection, UserSortField } from "../utils"
import { useDelayedFunction } from "../hooks/useDelayedFunction"
import Title from "./Title"
import { useSnackbar } from "../hooks/useSnackbarHook"

export default function MemberList() {
  const view = useView()
  const [sortBy, setSortBy] = useState<UserSortField>("firstname")
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc")
  const [searchString, setSearchString] = useState<string>("")
  const [pageNumber, setPageNumber] = useState(0)
  const [limit, setLimit] = useState(50)
  const setSearchStringDelayed = useDelayedFunction(setSearchString, 250)
  const { setSnackbarState } = useSnackbar()

  const { data: allMunicipalities } = useGetAllMunicipalitiesQuery({
    onError: (e) => {
      setSnackbarState({ message: e.message, type: "error" })
    },
  })
  const [municipalityFilter, setMunicipalityFilter] = useState(allMunicipalities?.getAllMunicipalities || [])

  const { data: searchData, loading: loadingSearch } = useSearchUsersQuery({
    variables: {
      input: {
        municipalityFilter,
        pageNumber,
        sortBy,
        sortDirection,
        searchString,
        limit,
      },
    },
    fetchPolicy: "no-cache",
    onError: (e) => {
      setSnackbarState({ message: e.message, type: "error" })
    },
    skip: !allMunicipalities?.getAllMunicipalities || !allMunicipalities?.getAllMunicipalities.length,
  })

  const users = searchData?.searchUsers.users

  const handleClickSort = (sortField: UserSortField) => {
    if (sortBy === sortField) {
      if (sortDirection === "asc") {
        setSortDirection("desc")
      } else {
        setSortDirection("asc")
      }
    } else {
      setSortBy(sortField)
    }
  }

  return (
    <React.Fragment>
      <Title>Members</Title>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <TextField
                id="outlined-basic"
                label="Namnsök"
                variant="outlined"
                onChange={(e) => {
                  setSearchStringDelayed(e.target.value)
                }}
              />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <Autocomplete
                multiple
                id="municipality-multi-filter"
                options={allMunicipalities?.getAllMunicipalities || []}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField {...params} label="Kommunfilter" />}
                onChange={(_, newValue) => {
                  setMunicipalityFilter(newValue)
                  setSortBy("municipality")
                }}
              />
            </TableCell>
            <TableCell colSpan={2} sx={{ verticalAlign: "bottom" }}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <>
                  {"Show"}
                  <Link
                    href="#"
                    sx={{ marginLeft: "4px", marginRight: "4px", fontWeight: limit === 20 ? "normal" : "bold" }}
                    onClick={() => setLimit(20)}>
                    20
                  </Link>
                  <Link
                    href="#"
                    sx={{ marginRight: "4px", fontWeight: limit === 50 ? "normal" : "bold" }}
                    onClick={() => setLimit(50)}>
                    50
                  </Link>
                  <Link
                    href="#"
                    sx={{ marginRight: "4px", fontWeight: limit === 100 ? "normal" : "bold" }}
                    onClick={() => setLimit(100)}>
                    100
                  </Link>
                  <Box sx={{ marginRight: "4px", marginLeft: "4px" }}>{"|"}</Box>
                </>
                {pageNumber > 0 && (
                  <Link
                    color="primary"
                    href="#"
                    onClick={() => {
                      setPageNumber(pageNumber + -1)
                    }}>
                    {"<<Previous"}
                  </Link>
                )}

                {pageNumber > 0 && users?.length === limit && (
                  <Box sx={{ marginRight: "4px", marginLeft: "4px" }}>{"|"}</Box>
                )}
                {users?.length === limit && (
                  <Link
                    color="primary"
                    href="#"
                    onClick={() => {
                      setPageNumber(pageNumber + 1)
                    }}
                    sx={{}}>
                    {"Next>>"}
                  </Link>
                )}
                <Box sx={{ marginLeft: "4px" }}>{`Page ${pageNumber + 1}/${
                  searchData?.searchUsers.totalPages ?? ""
                }`}</Box>
              </Box>
            </TableCell>
          </TableRow>

          <TableRow sx={{ backgroundColor: "grey.400" }}>
            <TableCell>Edit</TableCell>
            <TableCell>
              Name
              <IconButton
                sx={{ outlineColor: "black" }}
                onClick={() => {
                  handleClickSort("firstname")
                }}>
                {sortBy === "firstname" ? (
                  sortDirection === "asc" ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )
                ) : (
                  <KeyboardArrowDown />
                )}
              </IconButton>
            </TableCell>
            <TableCell>
              Email
              <IconButton
                onClick={() => {
                  console.log("Click email..")
                  handleClickSort("email")
                }}>
                {sortBy === "email" ? (
                  sortDirection === "asc" ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )
                ) : (
                  <KeyboardArrowDown />
                )}
              </IconButton>
            </TableCell>
            <TableCell>
              Municipality
              <IconButton
                sx={{ marginLeft: "40px" }}
                onClick={() => {
                  handleClickSort("municipality")
                }}>
                {sortBy === "municipality" ? (
                  sortDirection === "asc" ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )
                ) : (
                  <KeyboardArrowDown />
                )}
              </IconButton>
            </TableCell>
            <TableCell>Phone</TableCell>
            <TableCell align="right">
              Status
              <IconButton
                sx={{ marginLeft: "40px" }}
                onClick={() => {
                  handleClickSort("status")
                }}>
                {sortBy === "status" ? (
                  sortDirection === "asc" ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )
                ) : (
                  <KeyboardArrowDown />
                )}
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Fragment>
            {!users || loadingSearch ? (
              <TableRow id="loading-search">
                <TableCell>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              users?.map((user, i) => (
                <TableRow key={user._id} sx={{ backgroundColor: i % 2 !== 0 ? "grey.200" : "" }}>
                  <TableCell>
                    <IconButton onClick={() => view.setView("profile", user._id)}>
                      <LaunchIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{`${user.contactInfo?.firstname || "<empty>"} ${
                    user.contactInfo?.lastname || "<empty>"
                  }`}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.contactInfo?.municipality || "-"}</TableCell>
                  <TableCell>{user.contactInfo?.phone || "-"}</TableCell>
                  <TableCell align="right">{`${user.membership?.status || "-"}`}</TableCell>
                </TableRow>
              ))
            )}
          </Fragment>
        </TableBody>
      </Table>
    </React.Fragment>
  )
}
